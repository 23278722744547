/* eslint-disable no-nested-ternary */
import React, { FC, useMemo, ReactNode } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { ArrowRight } from './icons'
import globalClassNames from '../../styles/globalClassNames'
import useMousseOn from '../../hooks/useMousseOn'

type Props = {
  label: string
  varient?: 'primary' | 'secondary' | 'transparent' | 'black' | 'blue'
  showIcon?: boolean
  isLoading?: boolean
  disabled?: boolean
  decreasePxOnBreakPoint?: 'sm' | 'md'
  icon?: ReactNode
  onClick?: (e: any) => void
  iconBeforeText?: boolean
  className?: string
}

const Button: FC<Props> = ({
  onClick,
  label,
  varient,
  icon,
  showIcon,
  isLoading,
  disabled,
  decreasePxOnBreakPoint,
  iconBeforeText,
  className,
}) => {
  const { mouseOn, toggleMouseOn } = useMousseOn()
  const classes = useMemo(() => {
    let button
    let iconClass
    switch (varient) {
      case 'secondary':
        button = 'bg-white border-none hover:bg-white hover:text-primary text-primary'
        iconClass = 'text-white bg-primary'
        break
      case 'transparent':
        button = 'hover:bg-transparent text-white border-transparent'
        iconClass = 'text-primary bg-white'
        break
      case 'black':
        button = 'bg-black text-white border-none'
        break
      case 'blue':
        button = 'text-white bg-blue-400 border-blue-400 hover:bg-blue-600 hover:border-blue-600' // 'border-[#2192ac] bg-[#2192ac] text-white'
        break
      default:
        button = `bg-gradient-to-r from-button-from to-button-to text-white border-none opacity-1 hover:opacity-80`
        iconClass = mouseOn ? 'text-white bg-primary' : 'text-primary bg-white'
    }
    let px = 'px-3'
    if (decreasePxOnBreakPoint === 'sm') {
      px = 'px-3 md:px-6'
    } else if (decreasePxOnBreakPoint === 'md') {
      px = 'px-3 lg:px-6'
    }

    return {
      button: [
        disabled ? 'bg-gray' : `${button} border-2`,
        isLoading || disabled ? 'pointer-events-none' : '',
        'flex flex-row items-center',
        globalClassNames.transition,
        px,
      ].join(' '),
      iconClass,
    }
  }, [varient, mouseOn, decreasePxOnBreakPoint, isLoading, disabled])

  return (
    <button
      onClick={onClick}
      onMouseEnter={toggleMouseOn}
      onMouseLeave={toggleMouseOn}
      className={`${classes.button} text-[10px] sm:text-sm flex ${
        iconBeforeText ? ' flex-row-reverse' : ''
      } items-center gap-x-2 font-normal py-[6px] rounded-md ${className}`}
      type='button'
      disabled={isLoading || disabled}
    >
      <span className=''>{label}</span>
      {showIcon &&
        !isLoading &&
        (icon || (
          <span className={`${classes.iconClass} rounded-full p-1`}>
            <ArrowRight className='h-3 w-3' />
          </span>
        ))}
      {isLoading && <AiOutlineLoading3Quarters className='animate-spin' />}
    </button>
  )
}
Button.defaultProps = {
  varient: 'primary',
  showIcon: false,
  decreasePxOnBreakPoint: undefined,
  isLoading: undefined,
  disabled: undefined,
  onClick: undefined,
  icon: undefined,
  iconBeforeText: false,
  className: '',
}

export default Button
